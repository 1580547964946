
let {service} = require("node-back-client/src/services/api");
import store from "node-back-client/src/store/index";
import ToastService from "toast/src/services/toast"

class UserService {

    service

    constructor(baseUrl,resource) {
        this.service = new service(baseUrl,resource);
    }

    async Login(data) {
        let user = await this.service.post("me/login",data);
        store.methods.setToken(user.token);
        return user;
    }

    async Save(data) {
        let user = await this.service.post("me/save",data);
        ToastService.Notify({
            title:"Success",
            message:"Account has been updated."
        });
        store.methods.update(user)
    }

    async Delete(data) {
        await this.service.remove(`${data._id}`);
        ToastService.Notify({
            title:"Success",
            message:"Account has been deleted."
        });
    }

    async Create(data) {
        let user = await this.service.post("me/create",data);
        ToastService.Notify({
            title:"Success",
            message:"Account has been created."
        });
        return user;
    }

    async GetUserInfo() {
        return this.service.get("me/info");
    }

    async GetLoginUser() {
        return store.methods.getCurrentUser()
    }

    async GetUsers(filter,skip,limit) {
        return await this.service.post("fetch",{
            filter,skip,limit
        });
    }

    async Logout() {
        await this.service.get("me/logout");
        store.methods.setToken(null);
        localStorage.removeItem("user");
    }
}


class BaseService {

    service

    constructor(baseUrl,resource) {
        this.service = new service(baseUrl,resource);
    }


    async get() {
        return this.service.get(...arguments);
    }

    async postForm() {
        return this.service.postForm(...arguments);
    }

    async post() {
        return this.service.post(...arguments);
    }

    async remove() {
        return this.service.remove(...arguments);
    }

}



export const services = {
    user : new UserService("https://login.egcextremeunrealtechnology.com/api","users"),
    application : new BaseService("https://login.egcextremeunrealtechnology.com/api","applications"),
    user_application : new BaseService("https://login.egcextremeunrealtechnology.com/api","user_applications"),
}