import { createRouter, createWebHashHistory } from 'vue-router'
import {services} from "../service/user-api";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },{
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
  },{
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta:{
      auth:true
    }
  },{
    path: '/accounts',
    name: 'Accounts',
    component: () => import('../views/Accounts.vue'),
    meta:{
      auth:true
    }
  },{
    path: '/apps',
    name: 'Application',
    component: () => import('../views/Application.vue'),
    meta:{
      auth:true
    }
  },{
    path: '/apps/:id/:application_id',
    name: 'UserApplication',
    component: () => import('../views/UserApplication.vue'),
    meta:{
      auth:true
    }
  },{
    path: '/oauth/',
    name: 'Oauth',
    component: () => import('../views/OAuth.vue'),
    meta:{
      auth:true
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {

    let currentUser = await services.user.GetLoginUser()
    console.log(currentUser,"CurrentUser");
    if (!currentUser) {
      router.push(`?url=${encodeURIComponent("#"+to.fullPath)}`);
      next();
    } else{
      next();
    }
  } else {
    next();
  }
});


export default router
