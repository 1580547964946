<template>
  <router-view/>
  <ToastHandler style="z-index: 10000"></ToastHandler>
  <InsertModalHandler></InsertModalHandler>
  <ModalHandler></ModalHandler>
</template>
<script>
import ToastHandler from 'toast/src/components/ToastHandler'
import InsertModalHandler from 'modal/src/components/InsertModalHandler'
import ModalHandler from 'modal/src/components/ModalHandler'
export default {
  components:{ToastHandler,InsertModalHandler,ModalHandler}
}
</script>
<style>
#app,html,body {
  width: 100%;
  height: 100%;
}
</style>
